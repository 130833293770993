<template>
    <div class="grid-item">
        {{text}}
    </div>
</template>
<script>
export default {
    props: {
        text: String
    }
}
</script>
<style>

</style>
