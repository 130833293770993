import About from './About.vue';
import Contact from './Contact.vue';
import Portfolio from './Portfolio.vue';
import Solutions from './Solutions.vue';

export default {
  About,
  Contact,
  Portfolio,
  Solutions
}